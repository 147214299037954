import React from 'react';

function Destination(props) {

    return (
        <section className='about'>
                <div className="destination">
                    <h3 className="content__title">{props.title}</h3>
                    <div className="destination__information">
                    <div className='destination__description'>
                        <h4 className='destination__title'>Своим ходом</h4>
                        <p className="destination__text">{props.foot}</p>
                    </div>
                    <div className='destination__description'>
                        <h4 className='destination__title'>На машине</h4>
                        <p className="destination__text">{props.car}</p>
                    </div>
                    </div>
                </div>
                <iframe className="destination__foto" title={props.iframe} src={props.src}></iframe>
            </section>
    )
}

export default Destination;